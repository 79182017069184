import { checkCookie, setCookie } from 'helpers/cookie'

window.addEventListener('DOMContentLoaded', () => {
  const newsletter = document.querySelector('.newsletter') as HTMLElement
  const closeNewsletter = document.querySelector('.newsletter__close') as HTMLElement
  if (!closeNewsletter || !newsletter) return

  if (!checkCookie('newsletter')) newsletter.classList.add('display')

  closeNewsletter.addEventListener('click', (e) => {
    newsletter.classList.remove('display')
    const cookie = checkCookie('newsletter')
    if (!cookie) setCookie('newsletter', false, 90)
  })

  window.onNewsletterSuccess = function () {
    const cookie = checkCookie('newsletter')
    if (cookie) return
    const newsletter = document.querySelector('.newsletter') as HTMLElement
    setCookie('newsletter', true, 90)
    newsletter.classList.add('display')
  }
})
